.editing {
  .button_container {
    position: absolute;
    transform: translateX(-60px);
    z-index: 99;

    :global(.mo-edit-icon.active) {
      background-color: white;
      border: solid 1px #C20924;
      color: #C20924;
    }
  }
}
